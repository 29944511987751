body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
    opacity: .5;
    pointer-events: none;
}

.MuiListItem-button .icon, .MuiListItem-button .label {
    margin-right: 20px;
    pointer-events: none;
}

.MuiListItem-button .balance {
    text-align: right;
    margin-right: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.user {
    display: flex;
    align-items: center;
}

.user .MuiAvatar-root {
    margin-right: 10px;
}

.wallet {
    padding: 5px 8px !important;
}

.wallet .icon, .wallet .label {
    margin-right: 10px;
    pointer-events: none;
}

.card {
    margin: 50px auto;
    padding: 20px;
}

.addButton, .addText {
    margin: auto !important;
    display: block !important;
    text-align: center;
}

.people-container {
    max-width: 500px;
    margin: auto;
}

.people {
    display: flex;
    flex-wrap: wrap;
}

.person {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.person .avatar {
    margin-right: 10px;
}

.person .balance {
    background: #111;
    color: #fff;
    position: absolute;
    padding: 5px 7px;
    border-radius: 10px;
    bottom: 0;
    left: 20px;
    font-size: .7em;
}

.chatbox {
    max-width: 500px;
    margin: auto;
}

.chatForm {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 40px;
}

.chatInput {
    width: 100%;
}

.chatSubmit {
    float: right;
    display: block;
    position: relative;
    top: 10px;
}

.message {
    display: flex;
    margin-bottom: 20px;
    position: relative;
}

.message .balance {
    background: #111;
    color: #fff;
    position: absolute;
    padding: 5px 7px;
    border-radius: 10px;
    top: 60px;
    left: 20px;
    font-size: .7em;

}

.message .text {
    margin-left: 20px;
}

.message.sent {
    justify-content: flex-end;
}

.message.sent .text {
    order: 1;
    margin-left: 0;
    margin-right: 20px;
    text-align: right;
}
.message.sent .balance {
    left: auto;
    right: 20px;
}
.message.sent .avatar {
    order: 2;
}

.firebaseui-idp-text {
    display: none !important;
}
.firebaseui-idp-button {
    min-width: 0 !important;
    min-height: 0 !important;
    border-radius: 4px !important;
    padding: 7px 12px !important;
}
.firebaseui-idp-list {
    display: flex;
}
.firebaseui-list-item {
    margin-left: 10px !important;
    margin-bottom: 0 !important;
}
.firebaseui-card-content {
    padding: 0 !important;
}
.firebaseui-idp-password {
    background: #fff !important;
    padding: 8px 12px !important;
}
.firebaseui-idp-password::before {
    content: 'Login';
    font-size: 1em;
}
.firebaseui-idp-password img {
    display: none;
}
.mdl-card {
    display: flex;
    align-items: center;
    position: fixed !important;
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(1,1,1,.6) !important;
    justify-content: flex-start;
}
.mdl-card form{
    z-index: 1;
    background: #fff;
    position: relative;
    margin-top: 50px;
    padding: 20px;
    max-width: calc(100vw - 80px);
}

#root {
    padding: 10px 20px;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}
.MuiButton-root {
    margin: 10px !important;
}
.MuiButton-root a {
    color: #111;
    text-decoration: none;
}
.nav {
    display: flex;
    justify-content: center;
}


